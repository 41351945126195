import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12c91c05"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter_wrapper pt-2" }
const _hoisted_2 = { class: "filter_left_wrapper" }
const _hoisted_3 = { class: "filter_right_wrapper" }
const _hoisted_4 = { src: "/assets/img/filter.svg" }
const _hoisted_5 = { class: "mt-3 total_wrapper" }
const _hoisted_6 = { class: "total pl-2" }
const _hoisted_7 = { ref: "download" }
const _hoisted_8 = { class: "mt-2 table_wrapper" }

export function render(_ctx, _cache) {
  const _component_v_select = _resolveComponent("v-select")
  const _component_v_input = _resolveComponent("v-input")
  const _component_v_datatable = _resolveComponent("v-datatable")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isShowFilters && _ctx.isShowNewClientButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "new_client_mobile_button",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.$router.push('/client/create')))
        }, _toDisplayString(_ctx.$t('clients.add_client')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isShowFilters ? 'show_filtes_mobile' : 'hide_filtes_mobile')
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_select, {
            modelValue: _ctx.filterAgreement,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = $event => ((_ctx.filterAgreement) = $event)),
              _ctx.applyFilter
            ],
            label: 'clients.all.agreement',
            class: "w-100",
            items: _ctx.agreements,
            clearable: ""
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]),
          _createVNode(_component_v_select, {
            modelValue: _ctx.filterStatus,
            "onUpdate:modelValue": [
              _cache[2] || (_cache[2] = $event => ((_ctx.filterStatus) = $event)),
              _ctx.applyFilter
            ],
            label: 'clients.all.status',
            class: "status_filter_input",
            items: _ctx.statuses,
            clearable: ""
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_v_select, {
            class: "small_filter_input mr-4",
            modelValue: _ctx.filterSex,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = $event => ((_ctx.filterSex) = $event)),
              _ctx.applyFilter
            ],
            label: 'clients.all.sex',
            items: _ctx.sex,
            clearable: ""
          }, null, 8, ["modelValue", "label", "items", "onUpdate:modelValue"]),
          _createVNode(_component_v_input, {
            class: "small_filter_input date_input",
            modelValue: _ctx.filterDate,
            "onUpdate:modelValue": [
              _cache[4] || (_cache[4] = $event => ((_ctx.filterDate) = $event)),
              _ctx.applyFilter
            ],
            label: _ctx.$t('clients.all.start_date'),
            inputType: "date",
            height: "22",
            clearable: ""
          }, null, 8, ["modelValue", "label", "onUpdate:modelValue"])
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("p", {
          onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.clearAllFilters && _ctx.clearAllFilters(...args))),
          class: "clear_all_filters pl-2"
        }, _toDisplayString(_ctx.$t('clients.clear_all_filters')), 1)
      ])
    ], 2),
    _createElementVNode("button", {
      class: "filter_button",
      onClick: _cache[6] || (_cache[6] = $event => (_ctx.isShowFilters = !_ctx.isShowFilters))
    }, [
      _createTextVNode(_toDisplayString(!_ctx.isShowFilters ? _ctx.$t('clients.filter') : _ctx.$t('clients.hide')) + " ", 1),
      _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
        [_vShow, !_ctx.isShowFilters]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createElementVNode("p", _hoisted_6, null, 512), [
        [_vShow, _ctx.isLoad]
      ]),
      _withDirectives(_createElementVNode("p", { class: "total pl-2" }, _toDisplayString(_ctx.$t('clients.total')) + ": " + _toDisplayString(_ctx.tableData.length), 513), [
        [_vShow, !_ctx.isLoad]
      ]),
      _createElementVNode("div", null, [
        (_ctx.isShowNewClientButton)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "new_client",
              onClick: _cache[7] || (_cache[7] = $event => (_ctx.$router.push('/client/create')))
            }, _toDisplayString(_ctx.$t('clients.add_client')), 1))
          : _createCommentVNode("", true),
        (_ctx.isShowDownloadButton)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "load",
              onClick: _cache[8] || (_cache[8] = (...args) => (_ctx.downloadFile && _ctx.downloadFile(...args)))
            }, _toDisplayString(_ctx.$t('clients.load')), 1))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("a", _hoisted_7, null, 512), [
          [_vShow, false]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_v_datatable, {
        class: "pointer",
        columTemplates: _ctx.columTemplates,
        data: _ctx.tableData,
        isLoading: _ctx.isLoad,
        onClickOnRow: _ctx.openClient
      }, null, 8, ["columTemplates", "data", "isLoading", "onClickOnRow"])
    ])
  ], 64))
}